<template>
    <div class="main-content" style="height:100vh; background: #000000; background-repeat: no-repeat;">
        <div class="position-relative">
            
            <div class="d-flex justify-content-center pt-5">       
                <img class="w-100" :src="started"/>              
            </div>
        </div>
     
        <div class="mainpage pt-0">
            <div class="aligh-center">
               <div class="col-sm-14 mb-3 text-center">
                    <h5 class="head_style mb-2" style="font-family: 'Poppins', sans-serif;">
                        {{ $t("started") }}
                    </h5>      
                </div>
                
               <div class="col-sm-14 mb-3 text-center">
                    <p class="mb-2 text-16 text-white" style="font-family: 'Poppins', sans-serif; line-height: 1.5;">
                        {{ $t("started2") }}
                    </p>      
                </div>  

                <div>  
                    <b-button type="submit" class="mt-5 mb-4 btn-curved-wallet" variant="primary" block @click="$router.push('/web/sessions/signIn')">{{ $t("getstart") }}</b-button>
                </div>

               <div class="col-sm-14 mb-3 text-center">
                    <p class="text-12 text-grey" style="font-family: 'Poppins', sans-serif;">
                        {{ $t("started3") }}
                        <span style="color: white;">{{ $t("privacy_policy")}}</span>
                        <span style="color: grey;"> {{ $t("and")}} </span>
                        <span style="color: white;">{{ $t("term_of_use")}}</span>  
                    </p> 
                </div>  

            </div>
        </div>
        <Dialog ref="msg"></Dialog>
    </div>
</template>
  
<script>
import Dialog from "../../../components/dialog.vue";
import { mapGetters, mapActions } from "vuex";
export default {
    computed: {
        ...mapGetters(["lang"]),
    },
    components: {
        Dialog,
    },
    data() {
        return {
            started: require("../../../assets/images/etnex_get_started/started.png"),
        };
    },
    props: ["success"],
    methods: {
        ...mapActions(["signOut"]),
       
    },
    created() {
        localStorage.setItem("getStarted", 1);
        localStorage.setItem("etnexToken", '123');
    },
};
</script>
  
<style scoped>
.main-content {
    max-width: 420px;
    margin: auto;
}

</style>